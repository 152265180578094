window.mailEditor = function () {
    function waitForTinyMCEReady(callback) {
        if (window.tinymce) {
            callback();
        } else {
            console.log("Waiting for TinyMCE to load...");
            setTimeout(() => waitForTinyMCEReady(callback), 100);
        }
    }

    waitForTinyMCEReady(() => {
        tinymce.PluginManager.add('block_manager_plugin', function (editor) {
            const blocks = [
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/one-column-orange.png',
                    alt: 'One Column - Orange',
                    snippet: `<div><br></div><div class="colored-block colored-1">
                                    <h3>Tag Line</h3>
                                    <h2>Heading</h2>
                                    <p>Lorem Eu deserunt non, deserunt sint.</p>
                                 </div><div><br></div>`
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/one-column-gray.png',
                    alt: 'One Column - Gray',
                    snippet: `<div><br></div><div class="colored-block colored-2">
                                    <h3>Tag Line</h3>
                                    <h2>Heading</h2>
                                    <p>Lorem Eu deserunt non, deserunt sint.</p>
                                 </div><div><br></div>`
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/one-column-green.png',
                    alt: 'One Column - green',
                    snippet: `<div><br></div><div class="colored-block colored-3">
                                    <h3>Tag Line</h3>
                                    <h2>Heading</h2>
                                    <p>Lorem Eu deserunt non, deserunt sint.</p>
                                 </div><div><br></div>`
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/one-column-pink.png',
                    alt: 'One Column - Pink',
                    snippet: `<div><br></div><div class="colored-block colored-4">
                                    <h3>Tag Line</h3>
                                    <h2>Heading</h2>
                                    <p>Lorem Eu deserunt non, deserunt sint.</p>
                                 </div><div><br></div>`
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/three-column.png',
                    alt: 'Three Column',
                    snippet: `<table class="colored-table" role="presentation" width="100%" border="0" cellspacing="0" cellpadding="0">
                                      <tr>
                                        <td width="33.3%" valign="top" class="colored-1">
                                            <h2>Heading</h2>
                                            <p>Lorem Eu deserunt non, deserunt sint.</p>
                                        </td>

                                        <td width="33.3%" valign="top" class="colored-2">
                                            <h2>Heading</h2>
                                            <p>Lorem Eu deserunt non, deserunt sint.</p>
                                        </td>

                                        <!-- Column 3 -->
                                        <td width="33.3%" valign="top" class="colored-3">
                                            <h2>Heading</h2>
                                            <p>Lorem Eu deserunt non, deserunt sint.</p>
                                        </td>
                                      </tr>
                                    </table>`
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/all-points.jpg',
                    alt: 'All Points',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/all-points.jpg" alt="Expand Reach" />'
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/calling-all-goodnesses.jpg',
                    alt: 'Calling',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/calling-all-goodnesses.jpg" alt="Calling" />'
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/connect-with-confidence.jpg',
                    alt: 'Connect',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/connect-with-confidence.jpg" alt="Connect" />'
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/edm-getha-card.png',
                    alt: 'EDM Card',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/edm-getha-card.ong" alt="EDM Card" />'
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/empower-your-practice.jpg',
                    alt: 'Empower',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/empower-your-practiced.jpg" alt="Empower" />'
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/get-started-today.jpg',
                    alt: 'Get Started',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/get-started-today.jpg" alt="Get Started" />'
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/lets-get-it-together.jpg',
                    alt: 'Lets Get It Together',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/lets-get-it-together.jpg" alt="Lets Get It Together" />'
                },
                {
                    src: 'https://storage.googleapis.com/bridge_images/images/email-blocks/working-for-you.jpg',
                    alt: 'Working For You',
                    snippet: '<img class="block-image" src="https://storage.googleapis.com/bridge_images/images/email-blocks/working-for-you.jpg" alt="Working For You" />'
                },
            ];

            const openImageDialog = () => {
                const dialogApi = editor.windowManager.open({
                    title: 'Insert Block',
                    size: 'normal',
                    body: {
                        type: 'panel',
                        items: [
                            {
                                type: 'htmlpanel',
                                presets: 'document',
                                html: `
                                          <div class="block-items" style="max-height: 200px; overflow-y: auto;">
                                            ${blocks.map((img, index) => {
                                    return `
                                                  <div style="padding: 5px; cursor: pointer; border-bottom: 1px solid #ccc;" data-index="${index}">
                                                    <img src="${img.src}" alt="${img.alt}" style="width: 100%; display: block; margin-bottom: 5px;">
                                                  </div>
                                                `;
                                }).join('')}
                                          </div>
                                        `
                            }
                        ]
                    },
                    buttons: [
                        {
                            type: 'cancel',
                            text: 'Close'
                        }
                    ]
                });

                setTimeout(() => {
                    const panelEl = document.querySelector('.block-items');

                    if (panelEl) {
                        panelEl.addEventListener('click', (e) => {
                            const item = e.target.closest('[data-index]');
                            if (item) {
                                const index = parseInt(item.getAttribute('data-index'), 10);
                                const block = blocks[index];
                                editor.insertContent(block.snippet);
                                editor.windowManager.close();
                            }
                        });
                    }
                }, 100);
            };

            editor.ui.registry.addButton('blockManagerPluginButton', {
                text: 'Insert Block',
                onAction: openImageDialog
            });

            return {
                getMetadata() {
                    return {
                        name: 'Block Manager',
                        url: 'https://example.com'
                    };
                }
            };
        });

        tinymce.init({
            selector: "#body",
            content_css: "/email.css",
            base_url: "/assets/tinymce",
            suffix: ".min",
            images_upload_handler: (blobInfo) => {
                return new Promise((resolve, reject) => {
                    const csrfToken = document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute("content");

                    const xhr = new XMLHttpRequest();
                    xhr.open("POST", "/image");
                    xhr.setRequestHeader("X-CSRF-TOKEN", csrfToken);

                    const formData = new FormData();
                    formData.append("file", blobInfo.blob(), blobInfo.filename());
                    formData.append("group", "email");

                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            const json = JSON.parse(xhr.responseText);
                            // Resolve with the final image URL:
                            resolve(json.file.url);
                        } else {
                            reject("HTTP Error: " + xhr.status);
                        }
                    };

                    xhr.onerror = () => {
                        reject("Image upload failed due to a network error.");
                    };

                    xhr.send(formData);
                });
            },
            setup: (editor) => {
                editor.on("init", () => {
                    console.log("TinyMCE initialized successfully!");
                });
            },
            plugins: "lists link image table code block_manager_plugin",
            toolbar: "undo redo | bold italic | bullist numlist outdent indent | link image | code | blockManagerPluginButton",
            menu: {
                format: {
                    title: "Format",
                    items:
                        "bold italic underline strikethrough superscript subscript codeformat | blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat",
                },
            },
            style_formats: [
                {title: "Bold text", inline: "strong"},
                {title: "Italic text", inline: "em"},
                {title: "Underline text", inline: "u"},
                {title: "Red text", inline: "span", styles: {color: "red"}},
            ],
        });
    });
}