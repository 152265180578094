window.imageWidths = ['w-1/12', 'w-2/12', 'w-3/12', 'w-4/12', 'w-5/12', 'w-6/12', 'w-7/12', 'w-8/12', 'w-9/12', 'w-10/12', 'w-11/12', 'w-12/12'];

class Image {
    // Random container ID to avoid conflicts
    container_id = 'image-' + Math.random().toString(36).substr(2, 9);

    constructor({data}) {
        this.data = data;

        // Tuning settings
        this.settings = [
            {
                name: 'shrink',
                label: 'Shrink',
                callback: 'btnShrink',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M439 7c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8l-144 0c-13.3 0-24-10.7-24-24l0-144c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39L439 7zM72 272l144 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39L73 505c-9.4 9.4-24.6 9.4-33.9 0L7 473c-9.4-9.4-9.4-24.6 0-33.9l87-87L55 313c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8z"/></svg>`
            },
            {
                name: 'grow',
                label: 'Expand',
                callback: 'btnExpand',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M344 0L488 0c13.3 0 24 10.7 24 24l0 144c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512L24 512c-13.3 0-24-10.7-24-24L0 344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z"/></svg>`
            }
        ];
    }

    /**
     * Define the component toolbox
     * @returns {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            title: 'Image',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>',
        };
    }

    /**
     * Render the component HTML
     * @returns {HTMLDivElement}
     */
    render() {
        // Create the container element
        const container = document.createElement('div');

        container.innerHTML = `
            <div x-data="{
                    data: {
                        image: ${this.getDataValue('image', {url: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',alt: ''})},
                        showBackground: ${this.getDataValue('showBackground', true)},
                    },
                    focusedButtonIndex: null,
                    focusedImageIndex: null,
                    blurTimeout: null,
                    imageWidths: window.imageWidths,
                    getImageSize() {
                        return this.data.image.size || 'w-12/12';
                    },
                    getImageWrapSize() {
                        return this.data.image.wrap_size || 'w-12/12';
                    },
                    getAlignment() {
                        return this.data.image.alignment || 'justify-start';
                    },
                    getWrapClasses() {
                        return 'flex ' + getAlignment() + ' ' + this.getImageWrapSize() + ' ' + (this.data.image.wrapper_class || '');
                    },
                    getWrapStyles() {
                        let styles = 'margin-left: ' + (this.data.image.ml || '0') + '; margin-right: ' + (this.data.image.mr || '0') + '; margin-top: ' + (this.data.image.mt || '0') + '; margin-bottom: ' + (this.data.image.mb || '0') + ';';
                        styles +=  ' padding-left: ' + (this.data.image.pl || '0') + '; padding-right: ' + (this.data.image.pr || '0') + '; padding-top: ' + (this.data.image.pt || '0') + '; padding-bottom: ' + (this.data.image.pb || '0') + ';';
                        styles += 'background-color: ' + (this.data.image.bgcolor || '#ffffffff') + ';'; 
                        return styles;
                    },
                    getImageClasses() {
                        return ' ' + this.getImageSize() + ' ' + (this.data.image.image_classes || '');
                    },
                    getImageStyles() {
                        let styles = '';
                        return styles;
                    },
                    uploadImage(event) {
                        const file = event.target.files[0];
                        if (!file) {
                            this.message = 'No file selected';
                            return;
                        }
                        
                        const formData = new FormData();
                        formData.append('image', file);
                        
                        fetch('/image', {
                            method: 'POST',
                            headers: {
                                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content')
                            },
                            body: formData,
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success) {
                                this.data.image.url = data.file.url;
                            } else {
                                alert('Failed to upload image.');
                            }
                        })
                        .catch(error => {
                            alert('Error uploading image: error');
                        });
                    }
                }"
                x-on:update-data${this.container_id}.window="Object.assign($data.data, $event.detail)"
                x-on:request-data${this.container_id}.window="$dispatch('response-data${this.container_id}', $data.data)"
                id="${this.container_id}"
                class=""
                :class="getWrapClasses()"
                :style="getWrapStyles()"
                >
                

                <div class="w-full flex"
                     :class="{'justify-center': data.image.alignment === 'justify-center', 'justify-end': data.image.alignment === 'justify-end'}"
                     x-show="data.image.url != ''">
                             
                            <img 
                                :src="data.image.url"
                                :alt="data.image.alt"
                                :class="getImageClasses()"
                                :style="getImageStyles()"
                                class="rounded-2xl object-cover object-center w-full h-full sm:rounded-3xl"
                                @mouseover="clearTimeout(blurTimeout); focusedImageIndex = 1"
                                @mouseout="blurTimeout = setTimeout(() => { focusedImageIndex = null; }, 300)">

                            <div x-show="focusedImageIndex === 1"
                                class="tooltip"
                                @mouseover="clearTimeout(blurTimeout); focusedImageIndex = 1"
                                @mouseout="blurTimeout = setTimeout(() => { focusedImageIndex = null; }, 300)"
                                style="z-index: 500; position: absolute; top: 0%; left: 0; padding: 10px; background: white; border: 1px solid black;">
                                <div>
                                    <input type="file" @change="uploadImage($event)" accept="image/*">
                                    
                                    <div class="flex space-x-2">
                                        <div class="flex-1">
                                            <label for="image-alignment" class="block mt-2 text-sm font-semibold text-gray-700">Alignment</label>
                                            <select x-model="data.image.alignment" class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                                                <option value="justify-start">Left</option>
                                                <option value="justify-center">Center</option>
                                                <option value="justify-end">Right</option>
                                            </select>

                                        </div>
                                        <div class="flex-1">
                                            <label for="image-wrap-size" class="block mt-2 text-sm font-semibold text-gray-700">Wrap Size</label>
                                            <select x-model="data.image.wrap_size" class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                                                <template x-for="width in imageWidths">
                                                    <option :value="width" x-text="width"></option>
                                                </template>
                                            </select>
                                        </div>
                                        <div class="flex-1">
                                            <label for="image-size" class="block mt-2 text-sm font-semibold text-gray-700">Image Size</label>
                                            <select x-model="data.image.size" class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                                                <template x-for="width in imageWidths">
                                                    <option :value="width" x-text="width"></option>
                                                </template>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="font-semibold text-gray-700 mt-4">Margin</div>
                                    <div class="flex space-x-2">
                                        <div class="flex-1">
                                            <label for="image-margin-left" class="block mt-2 text-sm font-semibold text-gray-700">Left</label>
                                            <input type="text"
                                                    x-model="data.image.ml"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                        <div class="flex-1">
                                            <label for="image-margin-right" class="block mt-2 text-sm font-semibold text-gray-700">Right</label>
                                            <input type="text"
                                                    x-model="data.image.mr"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                        <div class="flex-1">
                                            <label for="image-margin-top" class="block mt-2 text-sm font-semibold text-gray-700">Top</label>
                                            <input type="text"
                                                    x-model="data.image.mt"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                        
                                        <div class="flex-1">
                                            <label for="image-margin-bottom" class="block mt-2 text-sm font-semibold text-gray-700">Bottom</label>
                                            <input type="text"
                                                    x-model="data.image.mb"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                    </div>
                                    
                                    <div class="font-semibold text-gray-700 mt-4">Padding</div>
                                    <div class="flex space-x-2">
                                        <div class="flex-1">
                                            <label for="image-padding-left" class="block mt-2 text-sm font-semibold text-gray-700">Left</label>
                                            <input type="text"
                                                    x-model="data.image.pl"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                        <div class="flex-1">
                                            <label for="image-padding-right" class="block mt-2 text-sm font-semibold text-gray-700">Right</label>
                                            <input type="text"
                                                    x-model="data.image.pr"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                        <div class="flex-1">
                                            <label for="image-padding-top" class="block mt-2 text-sm font-semibold text-gray-700">Top</label>
                                            <input type="text"
                                                    x-model="data.image.pt"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                        
                                        <div class="flex-1">
                                            <label for="image-padding-bottom" class="block mt-2 text-sm font-semibold text-gray-700">Bottom</label>
                                            <input type="text"
                                                    x-model="data.image.pb"
                                                    class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                        </div>
                                    </div>
                                    
                                    <label for="image-alt" class="block mt-2 text-sm font-semibold text-gray-700">Background Color</label>
                                    <input type="text"
                                            x-model="data.image.bgcolor"
                                            value="#ffffff"
                                            class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />


                                    
                                    <label for="image-alt" class="block mt-2 text-sm font-semibold text-gray-700">Alt text</label>
                                    <input type="text"
                                            x-model="data.image.alt"
                                            placeholder="Image alt text"
                                            class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />

                                    <label for="image-caption" class="block mt-2 text-sm font-semibold text-gray-700">Alt text</label>
                                    <input type="text"
                                            x-model="data.image.caption"
                                            placeholder="Image caption text"
                                            class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                            
                                    <label for="image-wrapper-class" class="block mt-2 text-sm font-semibold text-gray-700">Wrapper Classes</label>
                                    <input type="text"
                                            x-model="data.image.wrapper_class"
                                            placeholder="Wrapper Classes"
                                            class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />
                                            
                                    <label for="image-caption" class="block mt-2 text-sm font-semibold text-gray-700">Image Classes</label>
                                    <input type="text"
                                            x-model="data.image.image_classes"
                                            placeholder="Image Classes"
                                            class="mt-2 block w-full px-3 py-1.5 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" />


                                </div>
                            </div>
                        </div>

                <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"></div>
            </div>
        `;

        return container;
    }

    /**
     * Render settings for the block
     * @returns {HTMLDivElement}
     */
    renderSettings(){
        const wrapper = document.createElement('div');
        wrapper.classList.add('ce-popover__items')

        this.settings.forEach( tune => {
            let button = document.createElement('div');
            button.classList.add('ce-popover-item')

            button.innerHTML = `
                <div class="ce-popover-item__icon">
                    ${tune.icon}
                </div>
                <div class="ce-popover-item" data-item-name="move-up">
                    ${tune.name}
                </div>`

            wrapper.appendChild(button);

            button.addEventListener('click', () => {
                this[tune.callback]();
            });

            //button.addEventListener('click', () => {
            //    this.updateComponentData({ header: 'Updated Header', body: 'Updated body' });
            //button.classList.toggle('cdx-settings-button--active');
            //});

        });

        return wrapper;
    }

    async btnFlip() {
        const currentValue = await this.getComponentData('isLeft', true);
        this.updateComponentData({ isLeft: !currentValue });
    }

    async btnToggleBackground() {
        const currentValue = await this.getComponentData('showBackground', true);
        this.updateComponentData({ showBackground: !currentValue });
    }

    async btnShrink() {
        const image = await this.getComponentData('image', true);
        const imageSize = image.size ?? 'w-12/12';

        // Get the current size and go to the next size down, stop at 1/12
        const currentIndex = window.imageWidths.indexOf(imageSize);
        const newSize = window.imageWidths[currentIndex - 1] ?? 'w-1/12';

        image.size = newSize;

        this.updateComponentData({ image: image });
    }

    async btnExpand() {
        const image = await this.getComponentData('image', true);
        const imageSize = image.size ?? 'w-12/12';

        // Get the current size and go to the next size up, stop at 12/12
        const currentIndex = window.imageWidths.indexOf(imageSize);
        const newSize = window.imageWidths[currentIndex + 1] ?? 'w-12/12';

        image.size = newSize;

        this.updateComponentData({ image: image });
    }

    /**
     * Send the data to the Alpine component data
     * @param data
     */
    updateComponentData(data) {
        window.dispatchEvent(new CustomEvent(`update-data${this.container_id}`, { detail: data }));
    }

    /**
     * Get the data from Alpine
     * @returns {Promise<unknown>}
     */
    fetchDataFromComponent() {
        return new Promise((resolve) => {
            // Add event listener to handle the response
            window.addEventListener(`response-data${this.container_id}`, function handler(event) {
                window.removeEventListener(`response-data${this.container_id}`, handler); // Remove handler after receiving response
                resolve(event.detail);
            }, { once: true });

            // Dispatch the request event
            window.dispatchEvent(new CustomEvent(`request-data${this.container_id}`));
        });
    }

    /**
     * Save the data
     * @param blockContent
     * @returns {Promise<{header, body}>}
     */
    async save(blockContent) {
        // Get the data from the Alpine component
        const data = await this.fetchDataFromComponent();
        console.log(data)
        return data;
    }

    getDataValue(key, val = '') {
        let data = this.data?.[key] ?? val;

        if (typeof data === 'object') {
            data = JSON.stringify(data);
            data = data.replaceAll('"', "'")
        } else if (typeof data === 'string') {
            data = data.replaceAll("'", "\\'")
        }
        return data
    }

    async getComponentData(key, val = '') {
        const data = await this.fetchDataFromComponent()
        return data[key] ?? val;
    }
}

export default Image;