class Blog {
    constructor() {
    }

    static get toolbox() {
        return {
            title: 'Divider',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>',
        };
    }

    render() {
        const div = document.createElement('div');
        div.className = 'h-px my-8 bg-gray-200 border-0 dark:bg-gray-700';

        return div;
    }

    save(blockContent) {
        return {};
    }
}

export default Blog;